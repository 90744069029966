<template>
  <div class="row">
    <div class="col-md-6">
      <div class="input-group">
        <span class="input-group-text">ID</span>
        <input type="text" v-model="basedata.moonId" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-group">
        <span class="input-group-text">音檔來源</span>
        <input type="text" v-model="basedata.moonSource" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-group">
        <span class="input-group-text">網頁來源</span>
        <input type="text" v-model="basedata.moonUrl" />
      </div>
    </div>
    <div class="col-md-12">
      <div class="input-group">
        <button @click="DoAdd">送出</button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { PostMoon } from "../../api.js";
export default {
  setup() {
    const basedata = reactive({
      moonId: "",
      moonSource: "",
      moonUrl: "",
    });

    function DoAdd() {
      console.log("Do Add");
      var vData = {
        id: parseInt(basedata.moonId),
        source: basedata.moonSource,
        url: basedata.moonUrl,
      };
      PostMoon(vData)
        .then(function (response) {
          console.log("GetListData result:", response.data);
          basedata.MoonList = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    return { basedata, DoAdd };
  },
};
</script>

<style>
button {
  margin-top: 10px;
}
</style>